export const QUERY_KEYS = {
  //
  PUBLIC_VACANCY_SEARCH: "PUBLIC_VACANCY_SEARCH",
  PUBLIC_VACANCY_SEARCH_V2: "PUBLIC_VACANCY_SEARCH_V2",
  PUBLIC_VACANCY_COUNT: "PUBLIC_VACANCY_COUNT",
  PUBLIC_POPULAR_CATEGORIES: "PUBLIC_POPULAR_CATEGORIES",
  PUBLIC_LATEST_VACANCIES: "PUBLIC_LATEST_VACANCIES",
  MAIN_PAGE: "MAIN_PAGE",
  MAIN_PAGE_V2: "MAIN_PAGE_V2",
  REGISTRATION_DATA: "REGISTRATION_DATA",
  PERSON_REGISTER_FORM_DATA: "PERSON_REGISTER_FORM_DATA",
  TOP_COMPANIES: "TOP_COMPANIES",
  GET_CV: "GET_CV",

  POST_SEND_OTP: "POST /phone/otp",
  // Vacancy Apply
  POST_VACANCY_GUEST_APPLY: "POST_VACANCY_GUEST_APPLY",
  POST_VACANCY_USER_APPLY: "POST_VACANCY_USER_APPLY",
  GET_CHECK_APPLICANT_CREDENTIALS: "GET_CHECK_APPLICANT_CREDENTIALS",
  WIZARD_CACHE_DATA: "WIZARD_CACHE_DATA",
  DATA_FOR_VACANCIES: "DATA_FOR_VACANCIES",
  GET_COUNT_SAVE_APPLIED: "GET_COUNT_SAVE_APPLIED",
  GET_SAVED_APPLIED_STATUS: "GET_SAVED_APPLIED_STATUS",

  // Natural person
  GET_NATURAL_PERSON_DATA: "GET_NATURAL_PERSON_DATA",

  GET_LANGUAGE: "GET_LANGUAGE",

  GET_GLOBAL_USER_INFO: "GET_GLOBAL_USER_INFO",

  // vacancy
  GET_APPLIED_VACANCIES: "GET_APPLIED_VACANCIES",
  GET_SAVED_VACANCIES: "GET_SAVED_VACANCIES",
  VACANCY_GET_BY_ID: "VACANCY_GET_BY_ID",
  VACANCY_SIMILAR_GET_BY_ID: "VACANCY_SIMILAR_GET_BY_ID",

  VACANCY_GET_FILTER: "GET /api/natural-person/vacancy/filter",
  VACANCY_ADD_FILTER: "PUT /api/natural-person/vacancy/filters",

  // Subscriptions
  GET_SUBSCRIPTIONS: "GET_SUBSCRIPTIONS",
  GET_NOTIFICATIONS: "GET_NOTIFICATIONS",

  // Benefits
  GET_BENEFITS: "GET_BENEFITS",

  //
  GET_COMPANIES: "GET_COMPANIES",

  // Consents
  GET_USER_CONSENTS: "GET_USER_CONSENTS",
  POST_USER_CONSENTS: "POST_USER_CONSENTS",
  GET_USER_EXTERNAL_CONSENTS: "GET_USER_EXTERNAL_CONSENTS",
  POST_USER_EXTERNAL_CONSENTS: "POST_USER_EXTERNAL_CONSENTS",

  //
  USER_CV_TMP_FORM: "USER_CV_TMP_FORM",
  GET_LANGUAGES_COUNTRIES: "GET_LANGUAGES_COUNTRIES",

  // BALANCE CARDS
  GET_BALANCE: "GET_BALANCE",
  PUBLIC_VACANCY_PAGINATION_SEARCH_V2: "PUBLIC_VACANCY_PAGINATION_SEARCH_V2"
}
